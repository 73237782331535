.card-rounded {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(75% at 50% 0);
  background-repeat: no-repeat;
}
.custom-shadow {
  box-shadow: 0 9px 60px rgb(0 0 0 / 0.2);
}

.parent-class:hover .child-class {
  background-color: #ca4757;
  color: white;
  transition: 300ms;
}

.card-rounded1 {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(70% at 50% 0);
  background-repeat: no-repeat;
}
