@media only screen and (max-width: 688px) {
    #food-video iframe {
      position: fixed;
      width: 95vw !important;
      height: 53.44vw !important;
    }
  }
  

@media only screen and (min-width:768px)  {
  #food-video iframe{
    position: fixed;
    width: 100% ;
    height: 378px;
  
    
  }
}

@media only screen and (min-width:1536px)  {
  #food-video iframe{
    position: fixed;
    width: 100% ;
    height: 504px;
    
    
  }
} 
  