.contact-bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../../../src/Assets/contact-Recovered.webp);
    border-radius: 0px 100px;
    background-size: cover;
    background-position: center;

}

.map-bg {
    background: url(../../../src/Assets/map-background.jpg);

    background-size: cover;
    background-position: center;


}

.dashboard-bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.366), rgba(0, 0, 0, 0.2)), url(../../../src/Assets/employee-bg.jpg);
    background-size: cover;
    background-position: left;
    
    min-height: 100vh;
}

.another-banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.344) 0%, rgba(0, 0, 0, 0.396) 100%), url('../../../src/Assets/new-bg-contact.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    


}

.graduation-card{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.344) 0%, rgba(0, 0, 0, 0.396) 100%);
    background-size: cover;
    background-repeat: no-repeat;

}
.mobile-banner {
    background:url('../../../src/Assets/BROOKLYN-COVER-PHOTO.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    


}
.graduation-banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.072) 100%), url('../../../src/Assets/HHH_Certification/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


}

.graduation-description{
    background-image: linear-gradient(90deg,rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.562)
    );
    
}

.graduation-title{

    background-image: linear-gradient(90deg,rgba(5, 5, 5, 0.587), rgba(0, 0, 0, 0.575));

}