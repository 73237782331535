.bg-contact {
    background-image: url('../../../src/Assets/mailing.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.bg-contact-2 {
    background-image: linear-gradient(90deg, rgba(0, 199, 76, 0.123), rgba(0, 187, 255, 0.192));
}


.bg-simple {
    background-color: rgba(0, 0, 0, 0.466);
}