.classroom-bg{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.156) 0%, rgba(0, 0, 0, 0.255) 100%), url('../../../Assets/form-bg.png');
    /* background-image: url('../../../../src/assets/bg.png'); */
    min-height: 110vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   background-color:  rgb(228, 235, 244);
   
    
}