@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=PT+Serif&display=swap");
/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Source+Sans+Pro&display=swap");


@font-face {
  font-family: 'Spartan'; /* Name of the font */
  src: url('/src/Assets/fonts/spartan/Spartan-Regular.woff2') format('woff2'),
       url('/src/Assets/fonts/spartan/Spartan-Regular.woff') format('woff');
  font-weight: 400; /* Regular weight */
  font-style: normal; /* Regular style */
  font-display: swap; /* Ensures text remains visible during font load */
}

@font-face {
  font-family: 'Spartan-bold'; /* Name of the font */
  src: url('/src/Assets/fonts/spartan-bold/Spartan-Bold.woff2') format('woff2'),
       url('/src/Assets/fonts/spartan-bold/Spartan-Bold.woff') format('woff');
  font-weight: 400; /* Regular weight */
  font-style: normal; /* Regular style */
  font-display: swap; /* Ensures text remains visible during font load */
}

.spartan {
  font-family: 'Spartan', sans-serif;
}
.spartan-bold {
  font-family: 'Spartan-bold', sans-serif;
}


.roboto {
  font-family: "Roboto", sans-serif;
}

.playrify {
  font-family: "Playfair Display", serif;
}

.Poppins {
  font-family: "Poppins", sans-serif;
}
.open-sans {
  font-family: "Open Sans", sans-serif;
}

.source-sans {
  font-family: "Source Sans Pro", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  top: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pt-serif {
  font-family: "PT Serif", serif;
}

.main {
  width: 100%;
  height: 80vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
}

/* .content {
  position: absolute;
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
} 

/* */
.overlay {
  position: absolute;
  top: 10;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  /* background-color: #000000cc; */
}

.hover-effect {
  /* position: relative; */
  transition: 0.5s;
}

.hover-effect:hover {
  transform: translateY(-4px);
  z-index: 2;
}

.hover-effect2 {
  /* position: relative; */
  transition: 0.5s;
}

.hover-effect2:hover {
  transform: translateY(-14px);
  z-index: 2;
}

.primary {
  color: #ca4757;
}
