


.AllGraduation-banner {
    background:  url('../../../src/Assets/graduation/all-grad-cover.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


}


.newWeekend-banner {
    background:  url('../../Assets/New-Weekend/weeend-cover.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


}
.morning-banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.049) 0%, rgba(0, 0, 0, 0.072) 100%), url('../../Assets/MorningGraduation/morning-grad-cover.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


}
.Weekend-banner {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.049) 0%, rgba(0, 0, 0, 0.072) 100%), url('../../Assets/weekend-graduation/img-11.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


}
.Evening-banner{
    background:  url('../../Assets/EveningGraduation/img6.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


}
.NewEvening-banner{
    background:  url('../../Assets/NewEve/img1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.NewMorning-banner{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.111) 0%, rgba(0, 0, 0, 0.145) 100%), url('../../Assets/Morning-12-08-2023/morning-cover.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.Evening1215-banner{
    background: url('../../Assets/Evening-12-15-2023/main-cover.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.Evening29-banner{
    background: url('../../Assets/Evening-02-09-2024/main-cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.Weekend1224-banner{
    background: url('../../Assets/weekend-12-24-2023/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.Weekend021124-banner{
    background: url('../../Assets/Weekend-02-11-2024/weekend02112024.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.morning03012024-banner{
    background: url('../../Assets/morning-03-01-2024/main-cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.morning05032024-banner{
    background: url('../../Assets/morning-05-03-2024/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.morning03222024-banner{
    background: url('../../Assets/morning-03-22-2024/main-banner.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.morning04122024-banner{
    background: url('../../Assets/Morning-04-12-2024/morning-04-12-2024.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.weekEnd03242024-banner{
    background: url('../../Assets/Weekend-03-24-2024/main-cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.weekEnd05052024-banner{
    background: url('../../Assets/Weekend-05-05-20024/cover-photo.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.weekEnd07282024-banner{
    background: url('../../Assets/Weekend-07-28-2024/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.evening06282024-banner{
    background: url('../../Assets/Evening-6-28-2024/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.morning05242024-banner{
    background: url('../../Assets/Morning-05-24-2024/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.morning08162024-banner{
    background: url('../../Assets/Morning-08-16-24/Cover-Photo.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.morning06142024-banner{
    background: url('../../Assets/Morning-06-14-2024/cover-photo\ .webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.morning07052024-banner{
    background: url('../../Assets/Morning-07-05-2024/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.evening05032024-banner{
    background: url('../../Assets/Evening-05-03-2024/cover-photo-evening.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.evening05312024-banner{
    background: url('../../Assets/Evening-05-31-2024/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.evening07262024-banner{
    background: url('../../Assets/Evening-07-26-2024/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.morning07262024-banner{
    background: url('../../Assets/Morning-07-26-2024/another-cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.evening04052024-banner{
    background: url('../../Assets/Evening-04-05-20024/main-cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.Morning12292023-banner{
    background: url('../../Assets/Morning-12-29-2023/cover.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.Morning1192024-banner{
    background: url('../../Assets/Morning-01-19-2024/morning1192024.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}



.loading {
  /* Add blur or any other styles for the low-quality image */
  filter: blur(10px);
  opacity: 0.7; /* You can adjust the opacity to your preference */
}

/* Add styles for the loaded state */
.loaded {
  /* Add styles for the fully loaded, high-quality image */
  filter: blur(0); /* Reset blur for the high-quality image */
  opacity: 1;
}

.grid .relative.overflow-hidden img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }