.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  font-size: 0px !important;
}

.goog-logo-link,
.goog-te-gadget span a {
  display: none;

}

.goog-te-gadget {

  color: #CA4757 !important;
  display: flex;
 
}

.dark .goog-te-combo {
  background-color: #475569;
  outline: 2px solid white;
  color: white;
  padding: 2px;
  border-radius: 3px;
}

.goog-te-combo {

  outline: 2px solid #CA4757;
  padding: 2px;
  border-radius: 3px;

}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display:none;
  
}

@media only screen and (max-width: 768px) {

  .goog-te-combo{

    width: 140px;
  }

}

@media only screen and (min-width:1px) and (max-width:999px) {
  .goog-te-gadget {

    color: #CA4757 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}