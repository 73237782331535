@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .float-animation {
    animation: float 2.5s ease-in-out infinite;
  }
  


  .card-shadow {
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1); /* Box shadow */
    transition: box-shadow 0.2s ease; /* Smooth transition for hover */
  }
  
  .card-shadow:hover {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  }

  .image-container {
    overflow: hidden; /* Prevent image overflow */
  }
  
  .image-zoom:hover {
    transform: scale(1.1); /* Zoom the image on hover */
  }

  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
  
  .spin-animation {
    animation: spin 12s linear infinite;
  }