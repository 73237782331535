
.header-container .logo{
  width: 70px;
  height: 70px;
}

.title {
  font-size: 1em; /* Adjust base font size for the title */
  line-height: 1.2;
}



.tagline {
  font-size: 0.85em; /* Slightly smaller than title */
 
}

/* Responsive adjustments if needed */
@media (max-width: 360px) {
  .header-container .logo{
    width: 60px;
    height: 60px;
  }
  .text-container {
    max-width: 12em; /* Slightly narrower on very small screens */
  }

  .title {
    font-size: .9em;
  }

  .tagline {
    font-size: 0.75em;
  }
}

.nav-shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); /* Shadow only at the bottom */
  position: relative; /* Ensures no overlap issues */
  z-index: 10; /* Ensures it stays above other elements */
  background-color: transparent;
}

@media (min-width: 430px) {
  .header-container .logo {
    width: 80px;
    height: 80px;
  }

  .title {
    font-size: 1.2em; /* Increase title size for larger screens */
  }

  .tagline {
    font-size: 1em; /* Increase tagline size for larger screens */
  }
}




@media only screen and (max-width: 768px){

  .top-title{
    font-size: 14px;
  }

 
}


@media only screen and (min-width: 1000px) and (max-width: 1600px) {

  #video-player{
    height: 450px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .main-text {
    width: 90%;
    font-size: 40px;
  }

  .service-card {
    width: 90%;
  }

  .banner-text {
    margin-top: 50vh;
  }
  .nav-padding{
    padding-left: 16px;
    padding-right: 16px;
  }
}



@media only screen and (min-width: 1000px) and (max-width: 1500px) {

  #carousel-section{
    width: 90%;
  }
  .carousel-card{
    margin: 14px !important;
  }
  
}

@media only screen and (min-width: 1501px) and (max-width: 1600px) {

  #carousel-section{
    width: 85%;
  }


}


@media only screen and (min-width: 1200px) and (max-width: 1500px) {


  .nav-img {
    height: 70px;
    width: 70px;
  }
}

@media only screen and (min-width: 1100px ) and (max-width:1700px){

  #graduation-card{
    width: 90%;
  }

}

@media only screen and (min-width: 1401px ) and (max-width:1700px){
  .customNav-text {
    font-size: 22px;
  }
  #nav-ul li{
    font-size: 18px;
   margin-left: 9px;
   margin-right: 9px;
   
  }


}
@media only screen and (min-width: 1701px ) and (max-width:1899px){
  .customNav-text {
    font-size: 28px;
  }
  #nav-ul li{
    font-size: 18px;
   margin-left: 8px;
   margin-right: 8px;
   
  }


}
@media only screen and (min-width: 1100px ) and (max-width:1500px){

#graduation-text{
  top: 190px;
  padding: 24px;
  padding-left: 5%;
}

#graduation-text h1{
  
  font-size:30px ;

}
#graduation-text .medium-device-font{
  
  font-size:20px ;
  line-height: 1.2;

}
}

@media only screen and (min-width: 1501px ) and (max-width:1700px){

#graduation-text{
  top: 200px;
}

}
/* @media only screen and (min-width: 1367px ) and (max-width:1800px){
  .customNav-text {
    font-size: 22px;
  }
  #nav-ul li{
    font-size: 18px;
   margin-left: 8px;
   margin-right: 8px;
   
  }


} */

@media only screen and (min-width: 1000px) and (max-width: 1400px){
  .customNav-text {
    font-size: 22px;
  }
  #nav-ul li{
    font-size: 17px;
   margin-left: 6px;
   margin-right: 6px;
   
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1600px) {

  .polygon-shape{
    height: 230px !important;
  }


}

@media only screen and (min-width:1601px ) {

  .polygon-shape{
    height: 288px !important;
  }


}

@media only screen and (min-width: 1000px) and (max-width: 1599px) {
  .title-section {
    margin-top: 30vh;
  }
  .laptop-device{
    width: 90% ;
  }
  /* .institute-title {
    width: 90%;
  } */

  .medium-device{
    width: 80%;
  }

  .nav-user{
    display:none ;
  }

  
}

@media only screen and (min-width: 1500px) and (max-width: 1600px) {

  .medium-device{
    width: 75%;
  }


  .top-title {
    font-size: 18px;
  }
  .class-card {
    width: 35%;
  }
}

@media only screen and (min-width: 1850px) and (max-width: 10000px) {

 

  #topbar-text{
    font-size: 22px;
  }
  #topbar-text1{
    font-size: 22px;
  }
  .topbar-icon{
    font-size: 26px;
  }

  .goog-te-combo{
    font-size:16px !important ;
  }

  #nav-ul li{
    font-size: 18px;
   margin-left: 10px;
   margin-right: 10px;
   
  }
  .customNav-text {
    font-size: 36px;
  }

  .top-title {
    font-size: 18px;
    line-height: 2rem;
  }

  .nav-img {
    height: 100px;
    width: 100px;
  }

  .top-title {
    font-size: 22px;
  }

  .title-section {
    margin-top: 40vh;
  }

  .main {
    width: 100%;
    height: 79vh;
  }

  .countDown-bg {
    height: 400px;
  }

  .clock-section {
    width: 1200px;
  }

  .admission {
    width: 370px;
    height: 370px;
  }

  .bg-contact {
    height: 50vh;
  }
  .form-top {
    margin-top: 70px;
  }
  .class-card {
    width: 35%;
  }
  .big-card{
    height: 250px;
  }
  .big-card-2{
    height: 320px;
  }
  .big-class{
    width: 45%;

  }
  .class-card2 {
    width: 55%;
  }
  .license-section {
    width: 80%;
  }
  .class-carousel {
    height: 600px;
  }

  

  .license-font {
    font-size: 17px;
  }
  .mission-section {
    width: 80%;
  }
  .mission-text {
    margin-top: 20vh;
  }
  .welcome-message {
    width: 70%;
  }

  .ceo-picture {
    width: 80%;
  }

  .big-body{
    min-height: 60vh;
  }

  .big-screen{
    margin-top: 10vh;
  }
  .big-screen1{
    margin-top: 16vh;
  }

}


/* .customColor {
  background-color: rgba(255, 255, 255);

  position: sticky;
  top: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 500;
} */


.Sticky {
  position: fixed;
  top: 0;
  width: 100%;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  z-index: 500;
  background-color: white;
}