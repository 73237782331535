
.footer-bg{
    background-image: url('../../Assets/footer-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    
}

.footer-opacity{
    background-color: rgba(0, 0, 0, 0.556);
   
}
