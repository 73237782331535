.custom-zoom {
    transition: transform 0.5s ease-out;
  }
  
  .custom-zoom:hover {
    transform: scale(1.2);
  }

  /* WeekEndGraduation.css */
.loader-large {
  width: 150px;
  height: 150px;
}

@media (max-width: 768px) {
  .loader-large {
      width: 100px;
      height: 100px;
  }
}

@media (max-width: 480px) {
  .loader-large {
      width: 50px;
      height: 50px;
  }
}
