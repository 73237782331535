
.odometer-bg{
    background-image: url("../../../src/Assets/count-shape.png");
    background-size: cover;
    background-repeat: no-repeat;
 
   background-position: center;
}

.countdown-bg{
    background-color: rgba(2, 55, 66, 0.228);
}

.odometer {
    display: flex;
    
  }
  
  /* Media query for medium-sized screens and up */
  @media (min-width: 640px) {
    .odometer {
      flex-direction: row;
    }
  }
  
  /* Media query for small screens */
  @media (max-width: 639px) {
    .odometer {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }