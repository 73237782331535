
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 50px;
    line-height: 1;
    opacity: .75;
    color: #426d81;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before{
    margin-right: 20px !important;
}



.dark button.slick-arrow.slick-next:before{
  
    color: #fd6a80;
    
}
.dark button.slick-arrow.slick-prev::before{
    color: #fd6a80;
    
}

button.slick-arrow.slick-prev::before{
    margin-left: -30px !important;
}

@media only screen and (max-width:  768px) {

    .slick-prev:before, .slick-next:before {
        font-family: 'slick';
        font-size: 30px;
        line-height: 1;
        opacity: .75;
        color: #CA4757;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        z-index: 900;
        
    }
    button.slick-arrow.slick-prev::before{
        margin-left: -10px !important;
    }

 

}  


@media only screen 
  and (device-width: 428px) 
  and (device-height: 926px) 
  and (-webkit-device-pixel-ratio: 3) {


    .slick-prev:before, .slick-next:before {
        font-family: 'slick';
        font-size: 30px;
        line-height: 1;
        opacity: .75;
        color: #E8F3F8;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        z-index: 900;
        
    }
    button.slick-arrow.slick-prev::before{
        margin-left: -10px !important;
    }


   
}



