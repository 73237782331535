
.cottageAllVideo-swiper-page .swiper {
    aspect-ratio: 16 / 9;
    margin: 20px auto;
    overflow: hidden;  
  }
  


  .cottage-video-slider {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .cottage-video-image {
    background-size: cover;
    display: flex;
    align-items: flex-end;
    transition: all 0.3s ease-in-out;
  }
  
  .cottage-video-description {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 10px;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .cottage-video-slider:hover .cottage-video-description  {
    transform: translateY(0);
  }
  
  
   .cottageAllVideo-swiper-page .swiper-pagination-fraction {
   
    color: #333333;
    font-weight: 700;

   }

   .dark .cottageAllVideo-swiper-page .swiper-pagination-fraction {
   
    color: white;
   }

   .carousel-bg {
    background-image: 
        linear-gradient(to right, rgba(231, 248, 255, 0.781), rgba(243, 251, 255, 0.671)), /* Adjust transparency */
        url("https://res.cloudinary.com/di3wwp9s0/image/upload/v1734039015/ut6lsnuutdt0fgjginsa.webp");
    background-size: cover; /* Ensures the image covers the entire container */
    background-repeat: no-repeat; /* Prevents tiling of the background */
    background-position: center; /* Centers the background image */
    height: auto; /* Adjust height to ensure the section is full-screen or a desired height */
    display: flex; /* Use flex for content alignment */
    align-items: center; /* Vertically centers the content */
    justify-content: center; /* Horizontally centers the content */
  
}
   
.dark .carousel-bg{
    background-image: 
        linear-gradient(to right, rgba(71, 85, 105, 0.918), rgba(71, 85, 105, 0.836)), /* Adjust transparency */
        url("https://res.cloudinary.com/di3wwp9s0/image/upload/v1734039015/ut6lsnuutdt0fgjginsa.webp");
}


